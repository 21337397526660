<template>
    <div class="home card-box">
        <el-card class="login-card">
            <div slot="header" class="clearfix">
                <span>登录</span>
            </div>
            <el-form @keyup.native.prevent="loginKey" ref="form" :model="form" label-width="90px">
                <el-form-item label="用户名" prop="name" :rules="[{ required: true, message: '此项必填', trigger: 'blur' }]">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="pwd" :rules="[{ required: true, message: '此项必填', trigger: 'blur' }]">
                    <el-input show-password v-model="form.pwd"></el-input>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="login">登录</el-button>
                    <el-button @click="reset">重置</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>

<script>
export default {
    name: 'Home',
    components: {},
    data() {
        return {
            form: { name: '', pwd: '' },
        };
    },
    methods: {
        login() {
            this.$refs.form.validate(e => {
                if (e) {
                    fetch(`/admin-login`, {
                        method: 'POST',
                        body: JSON.stringify(this.form),
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                        .then(data => {
                            return data.json();
                        })
                        .then(data => {
                            console.log(3);
                            if (data.code !== 0) {
                                alert(data.msg);
                            } else {
                                location.href = '/admin/music';
                            }
                        });
                }
            });
        },
        reset() {
            this.form = { name: '', pwd: '' };
        },
        loginKey(c) {
            console.log(111, c.code);
            if (c.code === `Enter` || c.code === `NumpadEnter`) {
                this.login();
            }
        },
    },
};
</script>

<style scoped>
.card-box {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999999999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;
}
.login-card {
    width: 500px;
    height: 300px;
}
</style>
